<template>
    <div class="top__snack">
        <q-card flat bordered class="round__snack" :class="`rc__snack-${snack.type}`" v-if="snack.text">
            <q-card-section class="row items-center no-wrap">
                <q-icon :name="snack.icon" size="md" style="margin-right: 15px;"/>
                <div class="row items-center q-gutter-lg">
                    <div class="rc_textAlert">{{snack.text}}</div>
                </div>
                <q-space />
                <q-btn icon="close" flat @click="close" class="rc_textAlert"/>
            </q-card-section>
        </q-card>
    </div>
</template>
<script>
import store from '@/store';
import { computed } from 'vue';
export default({
    setup() {
        const close = () => {
            store.dispatch('snack/closeSnack');
        }
        return{
            snack: computed(() => store.getters['snack/getSnack']),
            close
        }
    },
})
</script>
<style lang="scss" scoped>

    .top__snack{
        position: fixed;
        right: 15px;
        z-index: 9999;
        top: 100px;
    }

    .round__snack{
        border-radius: 15px;
    }

</style>

