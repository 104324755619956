import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import snack from './snackbar';
import spinner from './spinner';
import api from '@/api';

export default createStore({
  plugins: [
    new VuexPersistence({
      storage: window.localStorage
    }).plugin
  ],
  state: {
    user: null,
    token: null,
    expireTime: null,
    rol: {
      id: null,
      descripcion: null
    },
    dias_restantes: null,
    tutorial:null,
    calculo_especial:null,
    mfa: null,
    valid: null,
    control_account : null,
    privacity_check : null,
    state_financiadas: null,
    state_no_financiadas: null,
  },
  getters: {
    getUser(state){return state.user},
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token){
      state.token = token;
    },
    setRolId(state, id){
      state.rol.id = id
    },
    setRolDescripcion(state, descripcion){
      state.rol.descripcion = descripcion
    },
    setExpireTime(state, time){
      state.expireTime = time;
    },
    setDiasRestantes(state, dias){
      state.dias_restantes = dias;
    },
    setVideoTutorial(state, payload){
      state.tutorial = payload;
    },
    setMFA(state, active){
      state.mfa = active;
    },
    setValid(state, access){
      state.valid = access;
    },
    setControlAccount(state, enabled){
      state.control_account = enabled;
    },
    setPrivacityCheck(state, checked){
      state.privacity_check = checked;
    },
    logout(state){
      state.user = null;
      state.token = null;
      state.rol.id = null;
      state.rol.descripcion = null;
      state.expireTime = null;
      state.dias_restantes = null;
      state.tutorial = null;
      state.mfa = null;
      state.valid = null;
      state.control_account = null;
      state.privacity_check = null;
      state.state_financiadas = null;
      state.state_no_financiadas = null;
    },
    setCalculoEspecial(state, payload){
      state.calculo_especial = payload;
    },
    setEstados(state, { financiadas =  null, no_financiadas = null }){
      state.state_financiadas = financiadas;
      state.state_no_financiadas = no_financiadas;
    }
  },
  actions: {
    async fetchEstados({ commit }) {
      try {
        const { status, data: { data } } = await api.getStateInforme();
        if(status === 200){
          commit('setEstados', data);
        }
      } catch (error) {
        console.error('Error al obtener los estados:', error);
      }
    }
  },
  modules: {
    snack,
    spinner
  }
})
