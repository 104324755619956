/* eslint-disable */
import store from "@/store";
export const authenticationRequired = (to: any, from: any, next: any) => {
    if (store.state.user != null && store.state.token != null) {
        if (store.state.mfa == 0) {
            if (store.state.rol.id == 4) {
                next();
            } else if (store.state.rol.id != 4) {
                if (to.path === '/asignacion_cupos') {
                    next('/empresas');
                } else if(to.path === '/tokenValidation'){
                    next('/empresas');
                }else{
                    next();
                }
            }
        } else if (store.state.mfa == 1 && store.state.valid == 0) {
            if (to.path === '/tokenValidation') {
                next();
            }else{
                next('/tokenValidation');
            }
        } else if (store.state.mfa == 1 && store.state.valid == 1) {
            if (store.state.rol.id == 4) {
                next();
            } else if (store.state.rol.id != 4) {
                if (to.path === '/asignacion_cupos') {
                    next('/empresas');
                } else if(to.path === '/tokenValidation'){
                    next('/empresas');
                }else{
                    next();
                }
            }
        }
    } else {
        store.commit("logout");
        next(`/login`)
    }
};