
import './styles/quasar.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import langES from 'quasar/lang/es.js'

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
  },
  framework: {
    lang: langES
  }
}