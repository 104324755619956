
import { onBeforeMount, ref , computed, onMounted, onUnmounted, watch } from 'vue'
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import Sidebar from './components/Sidebar.vue'
import router from '@/router/index'
import Snackbar from '@/components/SnackComponent.vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { useRoute } from 'vue-router'
import PrivacityNotice from './components/PrivacityNotice.vue'
import Spinner from './components/Spinner.vue'
import { useStore } from 'vuex'

export default {
  name: 'LayoutDefault',

  components: {
    Navbar,
    Footer,
    Sidebar,
    Snackbar,
    PrivacityNotice,
    Spinner
  },
  setup () {
    const route = useRoute();
    const hideSidebar = computed(() => route.meta.hideSidebar !== true);
    const $q = useQuasar();
    
    const showPrivacityDialog = computed(() => {
      const isAuthenticated = store.state.user != null;
      const hasAcceptedPrivacy = store.state.privacity_check == 1;
      const shouldShow = isAuthenticated && !hasAcceptedPrivacy;
      return shouldShow;
    });

    const store = useStore();
    let intervalId: ReturnType<typeof setInterval> | null = null;

    const checkAndFetchEstado = async () => {
      if(store.state.user == null) return;

      await store.dispatch('fetchEstados');
        if ((store.state.state_financiadas === 'ready' || store.state.state_financiadas === null) &&
        (store.state.state_no_financiadas === 'ready' || store.state.state_no_financiadas === null)) {
            if (intervalId) {
              clearInterval(intervalId);
              intervalId = null;
        }
      }
    };

    onMounted(() => {
      checkAndFetchEstado();
      intervalId = setInterval(checkAndFetchEstado, 8000);
    });

    watch(
      () => [store.state.state_financiadas, store.state.state_no_financiadas],
      ([financiadas, noFinanciadas]) => {
        if ((financiadas === 'ready' || financiadas === null) &&
            (noFinanciadas === 'ready' || noFinanciadas === null)) {
          if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }
        }else{
          checkAndFetchEstado();
          intervalId = setInterval(checkAndFetchEstado, 8000);
        }
      }
    );

    onUnmounted(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    });


    onBeforeMount(() => {
      // cambio de idioma en la tablas
      $q.lang.table.allRows = 'Todo'
      setInterval(() => {
          if (store.state.user == null) {
            return;
          }
          else{
            let expireTime: Date = new Date(store.state.expireTime != null  ? store.state.expireTime  : '');
            
            if ( Date.now() > expireTime.getTime()) {
              store.commit('logout');
              router.push('/login');
  
            }
          }
        }, 5 * 1000);

      var time: number;
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      
      function logout() {
        store.commit('logout');
        router.push('/login');
        
      }

      function resetTimer() {
        if(store.state.user == null){
          return;
        }
        else{
          clearTimeout(time);
          time = setTimeout(logout, 300000);
        }
      }

    })

    return {
      leftDrawerOpen: ref(false),
      hideSidebar,
      showPrivacityDialog
    }
  }
}
