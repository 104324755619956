<template>
    <div>
        <video-player class="rc-shadow rc-video-border1" :isYoutube="true" :options="videosRC.video"/>
    </div>
</template>

<script >
import VideoPlayer from "@/components/VideoPlayer.vue";
export default{
    name: 'VideoComponent',
    props: {
        urlVideo : String,
        posterVideo : String
    }, 
    components: {
        VideoPlayer
    },
    setup(props){

        let videosRC = {
            video: {
                autoplay: false,
                controls: true,
                fluid: true,
                preload: 'auto',
                sources: [
                    {
                        src: props.urlVideo,
                        type: "video/mp4"
                    }
                ],
                poster: props.posterVideo ? props.posterVideo : null

            }
        }
        return{
            videosRC
        }
    
    }   
}
</script>

<style lang="scss" scope>
    .rc-video-border1{
		div{
			div:first-child{
				iframe{
					border-radius: 25px;
				}
			}
			div:nth-child(2){
				border-radius: 25px;
			}
		}
	}
</style>