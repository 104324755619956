import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_footer = _resolveComponent("q-footer")!

  return (_openBlock(), _createBlock(_component_q_footer, {
    reveal: "",
    class: "rc__footer text-center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_toolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar_title, { style: {"font-size":"16px !important"} }, {
            default: _withCtx(() => [
              _createTextVNode("Copyright © 2014-" + _toDisplayString($setup.anio.getFullYear()) + " RedCapital. All rights reserved.", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}