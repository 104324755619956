
import API from '@/api'
import { ref } from 'vue';
import store from '@/store/index'
import router from '@/router';

export default {
    setup() {
        const showPrivacityDialog = ref(true);
        const fullScroll = ref(false);

        async function handleConfirm() {
            store.commit("setPrivacityCheck", '1');
            //eslint-disable-next-line
            //@ts-ignore
            API.privacityCheck(store.state.user.id);
            showPrivacityDialog.value = false;
        }

        const checkScroll = () => {
            const container = document.querySelector('.scroll-container');

            if (container) {
                fullScroll.value = ((container.scrollHeight - container.clientHeight) * .9) <= container.scrollTop
            }
        };

        return {
            handleConfirm, showPrivacityDialog , checkScroll, fullScroll, router
        }
    }
}
