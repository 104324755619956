// Definición de interfaces
interface Snack {
    text: string | null;
    type: string | null;
    icon: string | null;
}

interface RootState {
    snack: Snack;
}
const icons = [{event: 'success', icon:'check_circle'}, {event: 'error', icon: 'cancel'}, {event: 'info', icon: 'info'}]
const getIcon = (evento:string|null) => {
    const icon = icons.find(({ event }) => event === evento);
    return icon ? icon.icon : null;
};

export default {
    namespaced: true,
    state: (): RootState => ({
        snack: {
            text: null,
            type: null,
            icon: null,
        },
    }),
    getters: {
        getSnack: (state: RootState) => {
            return state.snack;
        },
    },
    mutations: {
        SET_SNACK(state: RootState, payload: Snack) {
            state.snack.text = payload.text;
            state.snack.type = payload.type;
            state.snack.icon = getIcon(payload.type);
        },
    },
    actions: {
        // eslint-disable-next-line
        // @ts-ignore
        snack({ commit }, payload: Snack) {
            commit('SET_SNACK', payload);

            setTimeout(() => {
             commit('SET_SNACK', {text:null, type: null} );
            }, 3000)
        },
        // eslint-disable-next-line
        // @ts-ignore
        closeSnack({ commit }){
            commit('SET_SNACK', {text:null, type: null} );
        }
    },
};