// Sidebar usuario pagador 
export const menuList = [
    {
        icon: 'o_description',
        label: 'Pronto Pago',
        collapse: false,
        to: 'pronto-pago'

    },
    {
        icon: 'o_description',
        label: 'Confirming',
        collapse: false,
        to:'confirming'

    },
    {
        icon: 'o_description',
        label: 'Factoring',
        collapse: false,
        to:'factoring'

    },
    {
        icon: 'o_menu_book',
        label: 'Informes de Carga',
        collapse: false,
        to:'informes'

    },
    {
        icon: 'o_menu_book',
        label: 'Informes de Factura',
        collapse: false,
        to:'informes-financiados'

    },
    {
        icon: 'o_analytics',
        iconColor: 'primary',
        label: 'Utilidad e indicadores',
        collapse: false,
        to:'utility'
    },
    {
        icon: 'o_menu_book',
        label: 'Mis cupos',
        collapse: false,
        to:'mis-cupos'
    },
    {
        icon: 'o_settings',
        label: 'Configuración',
        collapse: true,
        submenu: [
            {
                icon: 'domain',
                label: 'Mis empresas',
                collapse: false,
                to:'empresas'
        
            },
            {
                icon: 'person',
                label: 'Mis usuarios',
                collapse: false,
                to:'users'
            },
            {
                icon: 'trending_up',
                label: 'Tasas de proveedores',
                collapse: false,
                to:'proveedor-tasas'
            },
            {
                icon: 'list_alt',
                label: 'Proveedores autorizados',
                collapse: false,
                to: 'proveedor-autorizado'
            },
            {
                icon: 'folder_shared',
                label: 'Carga proveedores',
                collapse: false,
                to:'carga-proveedor'
            },
            {
                icon: 'view_list',
                iconColor: 'primary',
                label: 'Lista proovedores',
                collapse: false,
                to:'lista-proveedor'
            },
            {
                icon: 'lock',
                iconColor: 'primary',
                label: 'Seguridad',
                collapse: false,
                to:'security'
            }
        ]
    },
]

// Sidebar usuario admin
export const menuListAdmin = [
    {
        icon: 'o_description',
        label: 'Pronto Pago',
        collapse: false,
        to: 'pronto-pago'

    },
    {
        icon: 'o_description',
        label: 'Confirming',
        collapse: false,
        to:'confirming'

    },
    {
        icon: 'o_menu_book',
        label: 'Informes',
        collapse: false,
        to:'informes'

    },
    {
        icon: 'o_menu_book',
        label: 'Mis cupos',
        collapse: false,
        to:'mis-cupos'
    },
    {
        icon: 'o_settings',
        label: 'Configuración',
        collapse: true,
        submenu: [
            {
                icon: 'domain',
                label: 'Empresas',
                collapse: false,
                to:'empresas'
        
            },
            {
                icon: 'calendar_month',
                label: 'Logs',
                collapse: false,
                to:'logs'
        
            },
            {
                icon: 'trending_up',
                label: 'Tasas de proveedores',
                collapse: false,
                to:'proveedor-tasas'
            },
            {
                icon: 'person',
                label: 'Usuarios',
                collapse: false,
                to:'users'
            },
            {
                icon: 'list_alt',
                label: 'Proveedores autorizados',
                collapse: false,
                to: 'proveedor-autorizado'
            },
            {
                icon: 'o_build_circle',
                label: 'Parámetros globales',
                collapse: false,
                to: 'parametros-globales'
            },
            {
                icon: 'event_note',
                label: 'Auditoria',
                collapse: false,
                to: 'auditoria'
            },
            {
                icon: 'folder_shared',
                label: 'Carga proveedores',
                collapse: false,
                to:'carga-proveedor'
            },
            {
                icon: 'view_list',
                iconColor: 'primary',
                label: 'Lista proovedores',
                collapse: false,
                to:'lista-proveedor'
            }
        ]
    },
    // {
    //     icon: 'o_help_center',
    //     label: 'Ayuda',
    //     collapse: true,
    // },
]

export const menuListFondeador = [
    {
        icon: 'o_account_balance',
        label: 'Asignación cupos',
        collapse: false,
        to: 'asignacion-cupos'

    },
    {
        icon: 'person',
        label: 'Mis usuarios',
        collapse: false,
        to:'users'
    },
    // {
    //     icon: 'o_help_center',
    //     label: 'Ayuda',
    //     collapse: true,
    // // },
]

export const menuListControl = [
    {
        icon: 'person',
        label: 'Usuarios Controlables',
        collapse: false,
        to:'/user_control_list'
    },
]
