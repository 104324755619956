<!-- eslint-disable @typescript-eslint/no-empty-function -->
<template>
	<div>
		<video
			v-if="youtube"
			class="video-js vjs-big-play-centered vjs-default-skin disablePictureInPicture"
			controls
			:autoplay = false
			fluid="true"
			preload="auto"
			ref="videoPlayer"
			:data-setup="options.data"
			style="border-radius: 25px;"
			disablepictureincpicture
		>
		</video>
		<video v-if="!youtube" ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
	</div>
</template>

<script>
import videojs from 'video.js';
import { onBeforeUnmount, onMounted, ref } from 'vue';
	export default {
		name: "VideoPlayer",
		props: {
			options: {
				type: Object,
				default() {
					return {};
				}
			},
			isYoutube: {
				type: Boolean,
				default: false
			}
		},
		setup(props) {
            let videoPlayer = ref(null);
            let player = ref(null);
			
			let youtube = ref(props.isYoutube);	
			let isTakingScreenshot = ref(false);

			const takeScreenshot = () => {
				isTakingScreenshot.value = true;
			};
            
			onMounted(() => {
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				player.value = videojs(videoPlayer.value, props.options, function onPlayerReady() {})
				
				// document.addEventListener("contextmenu", function(e) {
				// 	e.preventDefault();
				// }, false);

				player.value.on('timeupdate', function() {
					if (isTakingScreenshot.value) {
						isTakingScreenshot.value = false;
					}
				});

				window.addEventListener('keyup', handleKeyUp);
				window.addEventListener('keydown', handleKeyDownMac);
			});

			const handleKeyUp = (event) => {
				if (event.keyCode === 44 || (event.metaKey && (event.key === "3" || event.key === "4"))) {
					takeScreenshot();
				}
			};

			const handleKeyDownMac= (e) => {
				if (e.metaKey && (e.key === "3" || e.key === "4")) {
					this.takeScreenshot();
				}
			}

			onBeforeUnmount(() => {
				if (player.value) {
					player.value.dispose()
				}
			});


			return {
				videoPlayer,
				player,
				youtube,
				isTakingScreenshot
			}
		},
	}
</script>

<style lang="scss" scoped>
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, );
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: 24px;
	}
	.video-js{
		background: transparent !important;
	}

</style>