<template>
    <div v-if="user != null && !$route.meta.hideNavbar">
        <div v-if="!drawer" style="position: sticky; top: 70px; z-index: 1">
            <q-btn flat @click="drawer = !drawer" round icon="menu" color="orange"/>    
        </div>
        <q-drawer
            show-if-above
            v-model="drawer"
            :width="150"
            :breakpoint="1263"
            class="rc_font_regular"
            style="color:#3D3D3D"
            v-if="user != null"
        >
            <q-scroll-area class="fit">
                <div v-if="user.is_admin == 0 && rol.id != 4">
                    <q-list>
                        <template v-for="(menuItem, index) in menuList" :key="index">
                            <q-expansion-item
                            expand-icon-toggle
                            expand-separator
                            :icon="menuItem.icon"
                            :label="menuItem.label"
                            v-if="menuItem.collapse == true"
                            dense-toggle
                            class="rc__sidebarCollapseItem flex-center"
                            :header-style="{ background:' #FF8A00 !important;' ,color:'white !important;'}"
                            
                            >
                            <q-list>
                                <template v-for="(submenuItem, index) in menuItem.submenu" :key="index">
                                    <q-item class="rc__sidebarItems flex-center" clickable :to="{name: submenuItem.to}">
                                        <q-item-section >
                                            <q-icon :name="submenuItem.icon" size="1.5rem"/>
                                        </q-item-section>
                                        <q-item-section>
                                            {{ submenuItem.label }}
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-list>
                            </q-expansion-item>

                            <q-item class="rc__sidebarItems flex-center" :to="{name: menuItem.to}" clickable v-ripple v-if="menuItem.submenu != true && menuItem.collapse != true">
                                    <q-item-section>
                                        <q-icon :name="menuItem.icon" size="1.5rem" />
                                    </q-item-section>
                                    <q-item-section>
                                        {{ menuItem.label }}
                                    </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </div>
                <div v-else-if="user.is_admin == 1">
                    <q-list>
                        <template v-for="(menuItem, index) in menuListAdmin" :key="index">
                            <q-expansion-item
                            expand-separator
                            :icon="menuItem.icon"
                            :label="menuItem.label"
                            v-if="menuItem.collapse == true"
                            dense-toggle
                            class="rc__sidebarCollapseItem flex-center"
                            >
                            <q-list>
                                <template v-for="(submenuItem, index) in menuItem.submenu" :key="index">
                                    <q-item class="rc__sidebarItems flex-center" clickable :to="{name: submenuItem.to}">
                                        <q-item-section >
                                            <q-icon :name="submenuItem.icon" size="1.5rem"/>
                                        </q-item-section>
                                        <q-item-section>
                                            {{ submenuItem.label }}
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-list>
                            </q-expansion-item>

                            <q-item class="rc__sidebarItems flex-center" :to="{name: menuItem.to}" clickable v-ripple v-if="menuItem.submenu != true && menuItem.collapse != true">
                                    <q-item-section>
                                        <q-icon :name="menuItem.icon" size="1.5rem" />
                                    </q-item-section>
                                    <q-item-section>
                                        {{ menuItem.label }}
                                    </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </div>
                <!-- Menu de fondeador -->
                <div v-else-if="rol.id == 4">
                    <q-list>
                        <template v-for="(menuItem, index) in menuListFondeador" :key="index">
                            <q-expansion-item
                            expand-separator
                            :icon="menuItem.icon"
                            :label="menuItem.label"
                            v-if="menuItem.collapse == true"
                            dense-toggle
                            class="rc__sidebarCollapseItem flex-center"
                            >
                            <q-list>
                                <template v-for="(submenuItem, index) in menuItem.submenu" :key="index">
                                    <q-item class="rc__sidebarItems flex-center" clickable :to="{name: submenuItem.to}">
                                        <q-item-section >
                                            <q-icon :name="submenuItem.icon" size="1.5rem"/>
                                        </q-item-section>
                                        <q-item-section>
                                            {{ submenuItem.label }}
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-list>
                            </q-expansion-item>

                            <q-item class="rc__sidebarItems flex-center" :to="{name: menuItem.to}" clickable v-ripple v-if="menuItem.submenu != true && menuItem.collapse != true">
                                    <q-item-section>
                                        <q-icon :name="menuItem.icon" size="1.5rem" />
                                    </q-item-section>
                                    <q-item-section>
                                        {{ menuItem.label }}
                                    </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </div>
            </q-scroll-area>
        </q-drawer>
    </div>
    <q-drawer
        show-if-above
        v-model="drawer"
        :width="150"
        :breakpoint="1263"
        class="rc_font_regular"
        style="color:#3D3D3D"
        v-if="user != null"
    >
        <q-scroll-area class="fit">
            <div v-if="user.is_admin == 0 && rol.id != 4 && user.control_account == 0">
                <q-list>
                    <template v-for="(menuItem, index) in menuList" :key="index">
                        <q-expansion-item
                        expand-icon-toggle
                        expand-separator
                        :icon="menuItem.icon"
                        :label="menuItem.label"
                        v-if="menuItem.collapse == true"
                        dense-toggle
                        class="rc__sidebarCollapseItem flex-center"
                        :header-style="{ background:' #FF8A00 !important;' ,color:'white !important;'}"
                        
                        >
                        <q-list>
                            <template v-for="(submenuItem, index) in menuItem.submenu" :key="index">
                                <q-item class="rc__sidebarItems flex-center" clickable :to="{name: submenuItem.to}">
                                    <q-item-section >
                                        <q-icon :name="submenuItem.icon" size="1.5rem"/>
                                    </q-item-section>
                                    <q-item-section>
                                        {{ submenuItem.label }}
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-list>
                        </q-expansion-item>

                        <q-item class="rc__sidebarItems flex-center" :to="{name: menuItem.to}" clickable v-ripple v-if="menuItem.submenu != true && menuItem.collapse != true">
                                <q-item-section>
                                    <q-icon :name="menuItem.icon" size="1.5rem" />
                                </q-item-section>
                                <q-item-section>
                                    {{ menuItem.label }}
                                </q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </div>
            <div v-else-if="user.is_admin == 1 && user.control_account == 0">
                <q-list>
                    <template v-for="(menuItem, index) in menuListAdmin" :key="index">
                        <q-expansion-item
                        expand-separator
                        :icon="menuItem.icon"
                        :label="menuItem.label"
                        v-if="menuItem.collapse == true"
                        dense-toggle
                        class="rc__sidebarCollapseItem flex-center"
                        >
                        <q-list>
                            <template v-for="(submenuItem, index) in menuItem.submenu" :key="index">
                                <q-item class="rc__sidebarItems flex-center" clickable :to="{name: submenuItem.to}">
                                    <q-item-section >
                                        <q-icon :name="submenuItem.icon" size="1.5rem"/>
                                    </q-item-section>
                                    <q-item-section>
                                        {{ submenuItem.label }}
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-list>
                        </q-expansion-item>

                        <q-item class="rc__sidebarItems flex-center" :to="{name: menuItem.to}" clickable v-ripple v-if="menuItem.submenu != true && menuItem.collapse != true">
                                <q-item-section>
                                    <q-icon :name="menuItem.icon" size="1.5rem" />
                                </q-item-section>
                                <q-item-section>
                                    {{ menuItem.label }}
                                </q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </div>
            <!-- Menu de fondeador -->
            <div v-else-if="rol.id == 4 && user.control_account == 0">
                <q-list>
                    <template v-for="(menuItem, index) in menuListFondeador" :key="index">
                        <q-expansion-item
                        expand-separator
                        :icon="menuItem.icon"
                        :label="menuItem.label"
                        v-if="menuItem.collapse == true"
                        dense-toggle
                        class="rc__sidebarCollapseItem flex-center"
                        >
                        <q-list>
                            <template v-for="(submenuItem, index) in menuItem.submenu" :key="index">
                                <q-item class="rc__sidebarItems flex-center" clickable :to="{name: submenuItem.to}">
                                    <q-item-section >
                                        <q-icon :name="submenuItem.icon" size="1.5rem"/>
                                    </q-item-section>
                                    <q-item-section>
                                        {{ submenuItem.label }}
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-list>
                        </q-expansion-item>

                        <q-item class="rc__sidebarItems flex-center" :to="{name: menuItem.to}" clickable v-ripple v-if="menuItem.submenu != true && menuItem.collapse != true">
                                <q-item-section>
                                    <q-icon :name="menuItem.icon" size="1.5rem" />
                                </q-item-section>
                                <q-item-section>
                                    {{ menuItem.label }}
                                </q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </div>
            <!-- Menu control de usuarios -->
            <div v-else-if="user.control_account == 1">
                <q-list>
                    <template v-for="(menuItem, index) in menuListControl" :key="index">
                        <q-expansion-item
                        expand-separator
                        :icon="menuItem.icon"
                        :label="menuItem.label"
                        v-if="menuItem.collapse == true"
                        dense-toggle
                        class="rc__sidebarCollapseItem flex-center"
                        >
                        <q-list>
                            <template v-for="(submenuItem, index) in menuItem.submenu" :key="index">
                                <q-item class="rc__sidebarItems flex-center" clickable :to="{name: submenuItem.to}">
                                    <q-item-section >
                                        <q-icon :name="submenuItem.icon" size="1.5rem"/>
                                    </q-item-section>
                                    <q-item-section>
                                        {{ submenuItem.label }}
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-list>
                        </q-expansion-item>

                        <q-item class="rc__sidebarItems flex-center" :to="{name: menuItem.to}" clickable v-ripple v-if="menuItem.submenu != true && menuItem.collapse != true">
                                <q-item-section>
                                    <q-icon :name="menuItem.icon" size="1.5rem" />
                                </q-item-section>
                                <q-item-section>
                                    {{ menuItem.label }}
                                </q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </div>
        </q-scroll-area>
    </q-drawer>
</template>

<style lang="scss">
.rc__sidebarItems{
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    font-weight: 600 !important;
    font-size: 12px !important;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.rc__sidebarItems:hover{
    background: #FF8A00 !important;
    color: white !important;    
}
.rc__sidebarCollapseItem .q-item.q-item-type {
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 12px !important;
    font-weight: 600 !important;
}
.rc__sidebarCollapseItem .q-item__section.q-item__section--side{
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50px;
    bottom: 20px;
}

.rc__sidebarCollapseItem .q-item__section.q-item__section--side.q-item__section--avatar{
    margin-left: 11px;
}

.rc__sidebarCollapseItem.q-expansion-item.q-expansion-item--expanded .q-item__section.q-item__section--side{
    color: #FF8A00 !important;   
}
.rc__sidebarCollapseItem.q-expansion-item.q-expansion-item--expanded .q-item__section.q-item__section--side.q-item__section--avatar{
    color: #FF8A00 !important;   
}
.rc__sidebarCollapseItem.q-expansion-item.q-expansion-item--expanded .q-item__label{
    color: #FF8A00 !important;   
}

.rc_font_regular{
    font-family: rc_font_regular;
}
// .rc__sidebarCollapseItem .q-item.q-item-type.row.no-wrap.q-item--clickable.q-link.cursor-pointer.q-focusable.q-hoverable:hover {
//     background: #FF8A00 !important;
//     color: white !important;
// }
.rc__sidebarCollapseItem.q-expansion-item.q-expansion-item--expanded .q-item.q-item-type.row.no-wrap.q-item--clickable.q-link.cursor-pointer.q-focusable.q-hoverable:hover .q-focus-helper{
    color: white !important;
}
.q-item.q-router-link--active, .q-item--active {
    background: #FF8A00 !important;
    color: white !important;
}
</style>

<script>

import { menuList, menuListAdmin, menuListFondeador, menuListControl } from "@/models/Links";
import {mapState} from 'vuex'
import { ref } from 'vue';
export default {
    name: "SideBar",
    computed: mapState(['user', 'rol']),
    data: function(){
        
        return{
            menuList, menuListAdmin, menuListFondeador, menuListControl, drawer: ref(true)
        }
    }
}

</script>