import { authenticationRequired } from '@/authentication'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: () => {
      return 'login'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/Register.vue')
  },
  {
    path: '/forget_password',
    name: 'restablecer-password',
    component: () => import('../views/ForgetPassword.vue')
  },
  {
    path: '/reset_password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/password_expired',
    name: 'password-expired',
    component: () => import('../views/PasswordExpired.vue'),
    props: true
  },

  // Usuario Logueado
  {
    path: '/empresas',
    name: 'empresas',
    component: () => import('../views/Business.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/pronto_pago',
    name: 'pronto-pago',
    component: () => import('../views/ProntoPago.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/confirming',
    name: 'confirming',
    component: () => import('../views/Confirming.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/informes',
    name: 'informes',
    component: () => import('../views/Reports.vue'),
    beforeEnter: authenticationRequired,
  },
  {
    path: '/informes_financiados',
    name: 'informes-financiados',
    component: () => import('../views/FundedReports.vue'),
    beforeEnter: authenticationRequired,
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/User.vue'),
    beforeEnter: authenticationRequired,
    props:true
  },
  {
    path: '/autorizado',
    name: 'proveedor-autorizado',
    component: () => import('../views/AuthorizedProviders.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/proveedor',
    name: 'carga-proveedor',
    component: () => import('../views/LoadProviders.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/proovedor/lista',
    name: 'lista-proveedor',
    component: () => import('../views/ListProviders.vue'),
    beforeEnter: authenticationRequired
  },  
  {
    path: '/mis_cupos',
    name: 'mis-cupos',
    component: () => import('../views/admin/MyQuotas.vue'),
    beforeEnter: authenticationRequired
  },   
  {
    path: '/asignacion_cupos',
    name: 'asignacion-cupos',
    component: () => import('../views/fondeador/AssingQuotas.vue'),
    beforeEnter: authenticationRequired
  },  
  {
    path: '/proveedor/tasas',
    name: 'proveedor-tasas',
    component: () => import('../views/TasasAplicadas.vue'),
    beforeEnter: authenticationRequired
  },  
  {
    path: '/tasas_globales',
    name: 'parametros-globales',
    component: () => import('../views/admin/GlobalParameters.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/auditoria',
    name: 'auditoria',
    component: () => import('../views/admin/Auditoria.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('../views/admin/Logs.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/panel_utilidad',
    name: 'utility',
    component: () => import('../views/Utility.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/factoring',
    name: 'factoring',
    component: () => import('../views/Factoring.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/tokenValidation',
    name: 'check-user',
    component: () => import('../views/MFACheck.vue'),
    beforeEnter: authenticationRequired,
    meta: { hideNavbar: true, hideSidebar: true }
  }, 
  {
    path: '/security/mfa',
    name: 'security',
    component: () => import('../views/ActivateMFA.vue'),
    beforeEnter: authenticationRequired
  },
  {
    path: '/user_control_list',
    name: 'usuarios',
    component: () => import('../views/control/ControlList.vue'),
    beforeEnter: authenticationRequired
  },  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
